import React from 'react';
import Grid from '@material-ui/core/Grid';
import InvoiceDataContainer from './InvoiceDataContainer.js';
import SectionTitle from '../SectionTitle.jsx';
import style from '../styles/AccountSummary.module.scss';
import ErrorBoundary from "../ErrorBoundary";
import LoaderPage from "../LoaderPage";

/**
 * Container for InvoiceDataContainer
 * @module Invoices
 */

function Invoices() {

    return (
        <>
            <LoaderPage/>
            <React.Fragment>
                <ErrorBoundary>
                    <SectionTitle variant="h1" style={style.welcome} title="My Invoices"/>
                </ErrorBoundary>
                <Grid container justify="center">
                    <ErrorBoundary>
                        <InvoiceDataContainer months={24} />
                    </ErrorBoundary>
                </Grid>
            </React.Fragment>
        </>
    )
}

export default Invoices;

